// ==================================================
//  Mainvisual スライダー
// ==================================================
document.addEventListener("DOMContentLoaded", function () {
  var splide = new Splide(".splide.js-splide-mainvisual", {
    type: "loop",
    arrows: false,
    pagination: false,
    drag: false,
    fixedWidth: 590,
    gap: 118,
    breakpoints: {
      768: {
        fixedWidth: 268,
        gap: 53,
      },
    },
    autoScroll: {
      speed: -0.42,
      pauseOnHover: false,
      pauseOnFocus: false,
    },
  });
  splide.mount(window.splide.Extensions);
});

// ==================================================
//   ローディング アニメーション
// ==================================================
window.addEventListener("load", function () {
  const ctaFixed = document.querySelector(".js-mainvisual");
  if (ctaFixed) {
    // Mainvisual
    setTimeout(function () {
      ctaFixed.classList.add("is-active");
    }, 600);
  }
});
